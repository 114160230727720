module.exports = {
  ranges: [
    {
      name: "azimuth",
      "Standard Curve Description": "Survey - Azimuth",
      "DISPLAY NAME": "AZI",
      units: "dega",
      low: "0",
      high: "360",
      m_units: "(degrees)",
      m_low: "0",
      m_high: "360",
      "": "",
    },
    {
      name: "bitor",
      "Standard Curve Description": "Bit Torque",
      "DISPLAY NAME": "BIT TOR",
      units: "kft.lbf",
      low: "0",
      high: "20",
      m_units: "(kN_m)",
      m_low: "0",
      m_high: "30",
      "": "",
    },
    {
      name: "bpos",
      "Standard Curve Description": "Block Position",
      "DISPLAY NAME": "BLOCK POS",
      units: "ft",
      low: "-10",
      high: "200",
      m_units: "(meters)",
      m_low: "-3",
      m_high: "70",
      "": "",
    },
    {
      name: "bit rpm",
      "Standard Curve Description": "Bit RPM",
      "DISPLAY NAME": "BIT RPM",
      units: "rpm",
      low: "0",
      // high: "600",
      high: "200",
      m_units: "RPM",
      m_low: "0",
      m_high: "200",
      "": "",
    },
    {
      name: "bit size",
      "Standard Curve Description": "Bit size",
      "DISPLAY NAME": "Bit Size",
      units: "in",
      low: "0",
      high: "40",
      m_units: "MM",
      m_low: "0",
      // m_high: "1000",
      m_high: "1200",
      "": "",
    },
    {
      name: "Btvd",
      "Standard Curve Description": "Bit True vertical depth",
      "DISPLAY NAME": "BTVD",
      units: "ft",
      low: "0",
      high: "15000",
      m_units: "(meters)",
      m_low: "0",
      m_high: "4500",
      "": "",
    },
    {
      name: "ann_pressure",
      "Standard Curve Description": "Annular Pressure",
      "DISPLAY NAME": "ANN PRESS",
      units: "psi",
      low: "0",
      high: "3000",
      m_units: "(kPa)",
      m_low: "0",
      // m_high: "20000",
      m_high: "21000",
      "": "",
    },
    {
      name: "cirh",
      "Standard Curve Description": "Circulating Hours",
      "DISPLAY NAME": "CIR HRS",
      units: "H",
      low: "0",
      high: "96",
      m_units: "h",
      m_low: "0",
      m_high: "96",
      "": "",
    },
    {
      name: "dbtm",
      "Standard Curve Description": "Bit Depth (measured)",
      "DISPLAY NAME": "BIT DEPTH",
      units: "ft",
      low: "0",
      high: "20000",
      m_units: "(meters)",
      m_low: "0",
      m_high: "6000",
      "": "",
    },
    {
      name: "diff",
      "Standard Curve Description": "Differential Pressure",
      "DISPLAY NAME": "DIFF PRESS",
      units: "psi",
      low: "0",
      high: "4500",
      m_units: "(kPa)",
      m_low: "0",
      m_high: "30000",
      "": "",
    },
    {
      name: "dmea",
      "Standard Curve Description": "Hole Depth (measured)",
      "DISPLAY NAME": "HOLE DEPTH",
      units: "ft",
      low: "0",
      high: "20000",
      m_units: "(meters)",
      m_low: "0",
      m_high: "6000",
      "": "",
    },
    {
      name: "dls",
      "Standard Curve Description": "Survey Dogleg Severity",
      "DISPLAY NAME": "DLS",
      units: "dega/100ft",
      low: "0",
      high: "15",
      m_units: "DEGREES/30 M",
      m_low: "0",
      m_high: "15",
      "": "",
    },
    {
      name: "mud density",
      "Standard Curve Description": "Mud Weight In",
      "DISPLAY NAME": "MW IN",
      units: "ppg",
      low: "7",
      // high: "16",
      high: "25",
      m_units: "(kg_per_m3)",
      m_low: "800",
      // m_high: "2000",
      m_high: "2500",
      "": "",
    },
    {
      name: "mud density out",
      "Standard Curve Description": "Mud Weight Out",
      "DISPLAY NAME": "MW OUT",
      units: "ppg",
      low: "7",
      // high: "16",
      high: "25",
      m_units: "(kg_per_m3)",
      m_low: "800",
      // m_high: "2000",
      m_high: "2500",
      "": "",
    },
    {
      name: "flow_out",
      "Standard Curve Description": "Flow out ",
      "DISPLAY NAME": "FLOW OUT",
      units: "%",
      low: "0",
      high: "100",
      m_units: "%",
      m_low: "0",
      m_high: "100",
      "": "",
    },
    {
      name: "gamma_ray",
      "Standard Curve Description": "Gamma Ray",
      "DISPLAY NAME": "GAMMA RAY",
      units: "gAPI",
      low: "0",
      high: "300",
      m_units: "API",
      m_low: "0",
      m_high: "300",
      "": "",
    },
    {
      name: "gas_total",
      "Standard Curve Description": "Total gas",
      "DISPLAY NAME": "TOTAL GAS",
      units: "%",
      low: "0",
      high: "100",
      m_units: "%",
      m_low: "0",
      m_high: "100",
      "": "",
    },
    {
      name: "hkl",
      "Standard Curve Description": "Hook Load",
      "DISPLAY NAME": "HOOK LOAD",
      units: "KLBF",
      low: "0",
      high: "300",
      m_units: "(kDaN)",
      m_low: "0",
      m_high: "140",
      "": "",
    },
    {
      name: "wob",
      "Standard Curve Description": "Weight on Bit",
      "DISPLAY NAME": "WOB",
      units: "KLBF",
      low: "0",
      high: "50",
      m_units: "(kDaN)",
      m_low: "0",
      m_high: "25",
      "": "",
    },
    {
      name: "inclination",
      "Standard Curve Description": "Survey - Inclination",
      "DISPLAY NAME": "INC",
      units: "dega",
      low: "0",
      high: "90",
      m_units: "(degrees)",
      m_low: "0",
      m_high: "90",
      "": "",
    },
    {
      name: "lag_dpt_md",
      "Standard Curve Description": "Lag Depth",
      "DISPLAY NAME": "LAG DEPTH",
      units: "ft",
      low: "0",
      high: "15000",
      m_units: "(meters)",
      m_low: "0",
      m_high: "4500",
      "": "",
    },
    {
      name: "tmv",
      "Standard Curve Description": "Total Mud Volume",
      "DISPLAY NAME": "TOT MUD VOL",
      units: "bbl",
      low: "0",
      high: "1000",
      m_units: "(m3)",
      m_low: "0",
      m_high: "150",
      "": "",
    },
    {
      name: "tt_vol",
      "Standard Curve Description": "Total TTK Volume",
      "DISPLAY NAME": "TOT TTK VOL",
      units: "bbl",
      low: "0",
      high: "100",
      m_units: "(m3)",
      m_low: "0",
      m_high: "15",
      "": "",
    },
    {
      name: "tt1_vol",
      "Standard Curve Description": "Trip Tank 1 Volume",
      "DISPLAY NAME": "TTK1 VOL",
      units: "bbl",
      low: "0",
      high: "50",
      m_units: "(m3)",
      m_low: "0",
      m_high: "10",
      "": "",
    },
    {
      name: "tt2_vol",
      "Standard Curve Description": "Trip Tank 2 Volume",
      "DISPLAY NAME": "TTK2 VOL",
      units: "bbl",
      low: "0",
      high: "50",
      m_units: "(m3)",
      m_low: "0",
      m_high: "10",
      "": "",
    },
    {
      name: "mfi",
      "Standard Curve Description": "Mud Flow In",
      "DISPLAY NAME": "MUD FLOW IN",
      units: "gpm",
      low: "0",
      high: "1000",
      m_units: "M^3/MIN",
      m_low: "0",
      m_high: "3",
      "": "",
    },
    {
      name: "obh",
      "Standard Curve Description": "On Bottom Hours",
      "DISPLAY NAME": "OB HOURS",
      units: "h",
      low: "0",
      high: "100",
      m_units: "h",
      m_low: "0",
      m_high: "100",
      "": "",
    },
    {
      name: "obr",
      "Standard Curve Description": "On Bottom ROP",
      "DISPLAY NAME": "OB ROP",
      units: "ft/h",
      low: "0",
      high: "500",
      m_units: "(m_per_hr)",
      m_low: "0",
      m_high: "150",
      "": "",
    },
    {
      name: "op",
      "Standard Curve Description": "Overpull",
      "DISPLAY NAME": "OVERPULL",
      units: "klbf",
      low: "0",
      high: "20",
      m_units: "(kDaN)",
      m_low: "0",
      m_high: "10",
      "": "",
    },
    {
      name: "pcas",
      "Standard Curve Description": "Casing Pressure",
      "DISPLAY NAME": "CASING PRESS",
      units: "psi",
      low: "0",
      high: "5000",
      m_units: "(kPa)",
      m_low: "0",
      m_high: "350000",
      "": "",
    },
    {
      name: "rop",
      "Standard Curve Description": "Rate Of Penetration",
      "DISPLAY NAME": "ROP",
      units: "ft/h",
      low: "0",
      high: "500",
      m_units: "(m_per_hr)",
      m_low: "0",
      m_high: "150",
      "": "",
    },
    {
      name: "rpm",
      "Standard Curve Description": "Rotation per Minute",
      "DISPLAY NAME": "RPM",
      units: "rpm",
      low: "0",
      high: "200",
      m_units: "RPM",
      m_low: "0",
      m_high: "200",
      "": "",
    },
    {
      name: "tot_spm",
      "Standard Curve Description": "Total SPM",
      "DISPLAY NAME": "TOTAL SPM",
      units: "spm",
      low: "0",
      high: "250",
      m_units: "SPM",
      m_low: "0",
      m_high: "300",
      "": "",
    },
    {
      name: "spm1",
      "Standard Curve Description": "Pump 1 strokes/min",
      "DISPLAY NAME": "SPM1",
      units: "spm",
      low: "0",
      high: "110",
      m_units: "SPM",
      m_low: "0",
      m_high: "110",
      "": "",
    },
    {
      name: "spm2",
      "Standard Curve Description": "Pump 2 strokes/min",
      "DISPLAY NAME": "SPM2",
      units: "spm",
      low: "0",
      high: "120",
      m_units: "SPM",
      m_low: "0",
      m_high: "120",
      "": "",
    },
    {
      name: "spm3",
      "Standard Curve Description": "Pump 3 strokes/min",
      "DISPLAY NAME": "SPM3",
      units: "spm",
      low: "0",
      high: "130",
      m_units: "SPM",
      m_low: "0",
      m_high: "140",
      "": "",
    },
    {
      name: "spp",
      "Standard Curve Description": "Standpipe Pressure",
      "DISPLAY NAME": "SPP",
      units: "psi",
      low: "0",
      high: "5000",
      m_units: "(kPa)",
      m_low: "0",
      m_high: "35000",
      "": "",
    },
    {
      name: "tf",
      "Standard Curve Description": "Tool Face",
      "DISPLAY NAME": "TOOL FACE",
      units: "dega",
      low: "0",
      high: "360",
      m_units: "(degrees)",
      m_low: "0",
      m_high: "360",
      "": "",
    },
    {
      name: "time",
      "Standard Curve Description": "Time of measurement",
      "DISPLAY NAME": "TIME",
      units: "s",
      low: "0",
      high: "",
      m_units: "SEC",
      m_low: "0",
      m_high: "",
      "": "",
    },
    {
      name: "tor",
      "Standard Curve Description": "Torque",
      "DISPLAY NAME": "TORQUE",
      units: "K FT.LBF",
      low: "0",
      high: "50",
      m_units: "(kN_m)",
      m_low: "0",
      m_high: "50",
      "": "",
    },
    {
      name: "temp_in",
      "Standard Curve Description": "Mud Temp In",
      "DISPLAY NAME": "TEMP IN",
      units: "degF",
      low: "0",
      high: "500",
      m_units: "(deg_c)",
      m_low: "0",
      m_high: "250",
      "": "",
    },
    {
      name: "temp_out",
      "Standard Curve Description": "Mud Temp Out",
      "DISPLAY NAME": "TEMP OUT",
      units: "degF",
      low: "0",
      high: "350",
      m_units: "(deg_c)",
      m_low: "0",
      m_high: "200",
      "": "",
    },
    {
      name: "ts1",
      "Standard Curve Description": "Pump 1 total strokes",
      "DISPLAY NAME": "PUMP 1 TOT STKS",
      units: "stks",
      low: "0",
      high: "5000",
      m_units: "(strokes)",
      m_low: "0",
      m_high: "5000",
      "": "",
    },
    {
      name: "ts2",
      "Standard Curve Description": "Pump 2 total strokes",
      "DISPLAY NAME": "PUMP 2 TOTSTKS",
      units: "stks",
      low: "0",
      high: "5000",
      m_units: "(strokes)",
      m_low: "0",
      m_high: "5000",
      "": "",
    },
    {
      name: "ts3",
      "Standard Curve Description": "Pump 3 total strokes",
      "DISPLAY NAME": "PUMP 3 TOT STKS",
      units: "stks",
      low: "0",
      high: "5000",
      m_units: "(strokes)",
      m_low: "0",
      m_high: "5000",
      "": "",
    },
    {
      name: "ts4",
      "Standard Curve Description": "Pump 4 total strokes",
      "DISPLAY NAME": "PUMP 4 TOT STKS",
      units: "stks",
      low: "0",
      high: "5000",
      m_units: "(strokes)",
      m_low: "0",
      m_high: "5000",
      "": "",
    },
    {
      name: "tot_stk",
      "Standard Curve Description": "Total Pump Strokes",
      "DISPLAY NAME": " TOT PUMP STKS",
      units: "stks",
      low: "0",
      high: "25000",
      m_units: "(strokes)",
      m_low: "0",
      m_high: "5000",
      "": "",
    },
    {
      name: "tvd",
      "Standard Curve Description": "TVD",
      "DISPLAY NAME": "TVD",
      units: "ft",
      low: "0",
      high: "15000",
      m_units: "(meters)",
      m_low: "0",
      m_high: "4000",
      "": "",
    },
    {
      name: "tvd - wits",
      "Standard Curve Description": "TVD - WITS",
      "DISPLAY NAME": "TVD - WITS",
      units: "ft",
      low: "0",
      high: "15000",
      m_units: "(meters)",
      m_low: "0",
      m_high: "4000",
      "": "",
    },
    {
      name: "tv1",
      "Standard Curve Description": "Mud Tank 1 Volume",
      "DISPLAY NAME": "MUD TANK 1 VOL",
      units: "bbl",
      low: "0",
      high: "500",
      m_units: "(m3)",
      m_low: "0",
      m_high: "100",
      "": "",
    },
    {
      name: "tv2",
      "Standard Curve Description": "Mud Tank 2 Volume",
      "DISPLAY NAME": "MUD TANK 2 VOL",
      units: "bbl",
      low: "0",
      high: "500",
      m_units: "(m3)",
      m_low: "0",
      m_high: "100",
      "": "",
    },
    {
      name: "tv3",
      "Standard Curve Description": "Mud Tank 3 Volume",
      "DISPLAY NAME": "MUD TANK 3 VOL",
      units: "bbl",
      low: "0",
      high: "500",
      m_units: "(m3)",
      m_low: "0",
      m_high: "100",
      "": "",
    },
    {
      name: "tv4",
      "Standard Curve Description": "Mud Tank 4 Volume",
      "DISPLAY NAME": "MUD TANK 4 VOL",
      units: "bbl",
      low: "0",
      high: "500",
      m_units: "(m3)",
      m_low: "0",
      m_high: "100",
      "": "",
    },
    {
      name: "tv5",
      "Standard Curve Description": "Mud Tank 5 Volume",
      "DISPLAY NAME": "MUD TANK 5 VOL",
      units: "bbl",
      low: "0",
      high: "500",
      m_units: "(m3)",
      m_low: "0",
      m_high: "100",
      "": "",
    },
    {
      name: "tv6",
      "Standard Curve Description": "Mud Tank 6 Volume",
      "DISPLAY NAME": "MUD TANK 6 VOL",
      units: "bbl",
      low: "0",
      high: "500",
      m_units: "(m3)",
      m_low: "0",
      m_high: "100",
      "": "",
    },
    {
      name: "tv7",
      "Standard Curve Description": "Mud Tank 7 Volume",
      "DISPLAY NAME": "MUD TANK 7 VOL",
      units: "bbl",
      low: "0",
      high: "500",
      m_units: "(m3)",
      m_low: "0",
      m_high: "100",
      "": "",
    },
    {
      name: "tv8",
      "Standard Curve Description": "Mud Tank 8 Volume",
      "DISPLAY NAME": "MUD TANK 8 VOL",
      units: "bbl",
      low: "0",
      high: "500",
      m_units: "(m3)",
      m_low: "0",
      m_high: "100",
      "": "",
    },
    {
      name: "tv9",
      "Standard Curve Description": "Mud Tank 9 Volume",
      "DISPLAY NAME": "MUD TANK 9 VOL",
      units: "bbl",
      low: "0",
      high: "500",
      m_units: "(m3)",
      m_low: "0",
      m_high: "100",
      "": "",
    },
    {
      name: "tv10",
      "Standard Curve Description": "Mud Tank 10 Volume",
      "DISPLAY NAME": "MUD TANK 10 VOL",
      units: "bbl",
      low: "0",
      high: "500",
      m_units: "(m3)",
      m_low: "0",
      m_high: "100",
      "": "",
    },
    {
      name: "tv11",
      "Standard Curve Description": "Mud Tank 11 Volume",
      "DISPLAY NAME": "MUD TANK 11 VOL",
      units: "bbl",
      low: "0",
      high: "500",
      m_units: "(m3)",
      m_low: "0",
      m_high: "100",
      "": "",
    },
    {
      name: "tv12",
      "Standard Curve Description": "Mud Tank 12 Volume",
      "DISPLAY NAME": "MUD TANK 12 VOL",
      units: "bbl",
      low: "0",
      high: "500",
      m_units: "(m3)",
      m_low: "0",
      m_high: "100",
      "": "",
    },
    {
      name: "tv13",
      "Standard Curve Description": "Mud Tank 13 Volume",
      "DISPLAY NAME": "MUD TANK 13 VOL",
      units: "bbl",
      low: "0",
      high: "500",
      m_units: "(m3)",
      m_low: "0",
      m_high: "100",
      "": "",
    },
    {
      name: "tv14",
      "Standard Curve Description": "Mud Tank 14 Volume",
      "DISPLAY NAME": "MUD TANK 14 VOL",
      units: "bbl",
      low: "0",
      high: "500",
      m_units: "(m3)",
      m_low: "0",
      m_high: "100",
      "": "",
    },
    {
      name: "tv15",
      "Standard Curve Description": "Mud Tank 15 Volume",
      "DISPLAY NAME": "MUD TANK 15 VOL",
      units: "bbl",
      low: "0",
      high: "500",
      m_units: "(m3)",
      m_low: "0",
      m_high: "100",
      "": "",
    },
    {
      name: "MTF",
      "Standard Curve Description": "",
      "DISPLAY NAME": "MTF",
      units: "deg",
      low: "0",
      high: "360",
      m_units: "(degrees)",
      m_low: "0",
      m_high: "360",
      "": "",
    },
    {
      name: "GTF",
      "Standard Curve Description": "",
      "DISPLAY NAME": "GTF",
      units: "degree",
      low: "-180",
      high: "180",
      m_units: "(degrees)",
      m_low: "-180",
      m_high: "180",
      "": "",
    },
    {
      name: "gainloss",
      "Standard Curve Description": "Gain Loss",
      "DISPLAY NAME": "Gain Loss",
      units: "bbls",
      low: "-100",
      high: "100",
      m_units: "m3",
      m_low: "-20",
      m_high: "-20",
      "": "",
    },
    {
      name: "stickslipindex",
      "Standard Curve Description": "stick slip index",
      "DISPLAY NAME": "stick slip index",
      units: "unitless",
      low: "0",
      high: "1",
      m_units: "unitless",
      m_low: "0",
      m_high: "1",
      "": "",
    },
    {
      name: "depthofcut",
      "Standard Curve Description": "depth of cut",
      "DISPLAY NAME": "depth of cut",
      units: "in/rev",
      low: "0",
      high: "1",
      m_units: "mm/rev",
      m_low: "0",
      m_high: "25",
      "": "",
    },
    {
      name: "simplifiedmse",
      "Standard Curve Description": "simplifiedmse",
      "DISPLAY NAME": "simplifiedmse",
      units: "psi",
      low: "0",
      high: "500000",
      m_units: "kpa",
      m_low: "0",
      m_high: "5000000",
      "": "",
    },
  ],
};
